//Default Overrides For Styles In Minima 
// If you wish to override any of this CSS, do so in _sass/minima/custom-styles.css

.post img {
        display: block;
        // border:1px solid #021a40;
        vertical-align: top;
        margin-left: auto;
        margin-right: auto;
}

img.emoji{
      display: inline !important;
      vertical-align: baseline !important;
}

.post figcaption {
  text-align: center;
  font-size: .8rem;
  font-style: italic;
  color: light-grey;
}

.page-content {
        -webkit-font-smoothing: antialiased !important;
        text-rendering: optimizeLegibility !important;
        font-family: "Segoe UI", SegoeUI, Roboto, "Segoe WP", "Helvetica Neue", "Helvetica", "Tahoma", "Arial", sans-serif !important;
}

// make non-headings slightly lighter
.post-content p, .post-content li {
   font-size: 20px;
   color: #515151;
}

.post-link{
    font-weight: normal;
}

// change padding of headings
h1 {
    margin-top:2.5rem !important;
}

h2 {
    margin-top:2rem !important;      
}

h3, h4 {
    margin-top:1.5rem !important;      
}

p {
    margin-top:1rem !important; 
    margin-bottom:1rem !important;   
}

h1, h2, h3, h4 {
    font-weight: normal !important;
    margin-bottom:0.5rem !important;  
    code {
      font-size: 100%;
    }
}

pre {
    margin-bottom:1.5rem !important; 
}

// make sure the post title doesn't have too much spacing
.post-title { margin-top: .5rem !important; }

li {
  h3, h4 {
  margin-top:.05rem !important;
  margin-bottom:.05rem !important;
  }
  .post-meta-description {
    color: rgb(88, 88, 88);
    font-size: 15px;
    margin-top:.05rem !important;
    margin-bottom:.05rem !important;
  }
}



//  Code Folding
 details.description[open] summary::after {
  content: attr(data-open);
}

details.description:not([open]) summary::after {
  content: attr(data-close);
}

// Notebook badges
.notebook-badge-image {
  border:0 !important;
}

// Adjust font size for footnotes.
.footnotes {
  font-size: 12px !important;
  p, li{
    font-size: 12px !important;
 } 
}

// Adjust with of social media icons were getting cut off
.social-media-list{
  .svg-icon {
    width: 25px !important;
    height: 23px !important;
  }
}

// Make Anchor Links Appear Only on Hover

.anchor-link {
  opacity: 0;
  padding-left: 0.375em;
  \-webkit-text-stroke: 1.75px white;
  \-webkit-transition: opacity 0.2s ease-in-out 0.1s;
  \-moz-transition: opacity 0.2s ease-in-out 0.1s;
  \-ms-transition: opacity 0.2s ease-in-out 0.1s;
}

h1:hover .anchor-link,
h2:hover .anchor-link,
h3:hover .anchor-link,
h4:hover .anchor-link,
h5:hover .anchor-link,
h6:hover .anchor-link {
  opacity: 1;
}


// category tags
.category-tags {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
  font-size: 105%;
}

// Custom styling for homepage post previews
.post-meta-title, .post-meta{
  margin-top: .25em !important;
  margin-bottom: .25em !important;
  font-size: 105%;
}

.page-description {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
  color: #585858;
  font-size: 115%;
}

// Custom styling for category tags
.category-tags-icon {
  font-size: 75% !important;
  padding-left: 0.375em;
  opacity: 35%;
}
.category-tags-link {
  color:rgb(187, 129, 129) !important;
  font-size: 13px !important;
}

// Search Page Styles
.js-search-results {padding-top: 0.2rem;}
.search-results-list-item {padding-bottom: 1rem;}
.search-results-list-item .search-result-title {
  font-size: 16px;
  color: #d9230f;
}
.search-result-rel-url {color: silver;}
.search-results-list-item a {display: block; color: #777;}
.search-results-list-item a:hover, .search-results-list-item a:focus {text-decoration: none;}
.search-results-list-item a:hover .search-result-title {text-decoration: underline;}

.search-result-rel-date {
  color: rgb(109, 120, 138);
  font-size: 14px;
}

.search-result-preview {
  color: #777;
  font-size: 16px;
  margin-top:.02rem !important;
  margin-bottom:.02rem !important;
}
.search-result-highlight {
  color: #2e0137;
  font-weight:bold;
}

table {
  white-space: normal;
  max-width: 100%;
  font-size: 100%;
  border:none;
  th{
    text-align: center! important;
  }
}

// customize scrollbars
::-webkit-scrollbar {
  width: 14px;
  height: 18px;
}
::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: #9D9D9D;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}

// Wrap text outputs instead of horizontal scroll
.output_text.output_execute_result {
  pre{
    white-space: pre-wrap;
  }
}


// Handling large charts on mobile devices
// @media only screen and (max-width: 1200px) {
//   /* for mobile phone and tablet devices */
  .output_wrapper{
    overflow: auto;
  }
// }

.svg-icon.orange{
  width: 30px;
  height: 23px;
}

.code_cell {
  margin: 1.5rem 0px !important;
}

pre code {
  font-size: 15px !important;
}

// Handle youtube videos, so they dont break on mobile devices
.youtube-iframe-wrapper {
  position: relative;
  padding-bottom: 56.10%;
  height: 0;
  overflow: hidden;
}

.youtube-iframe-wrapper iframe,
.youtube-iframe-wrapper object,
.youtube-iframe-wrapper embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
